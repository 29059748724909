import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Modal, Button } from 'react-bootstrap';
import MasterAssociationImage from './images/master_association_image.jpg';
import mainlogo from './images/riverwalk logo.png';

const LoginPage = () => {
    const navigate = useNavigate();
    const rememberedUsername = localStorage.getItem('rememberedUsername') || '';
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        fetch('/api/auth/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username, password }),
            credentials: 'include'
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            } else {
                return response.json();
            }
        })
        .then(data => {
            console.log('data.tempPasswordUsed:', data.tempPasswordUsed);
            if (data.tempPasswordUsed) {
                const setPasswordUrl = `/setPassword/${data.setPasswordToken}/${username}`;
                // Redirect the user to the setPassword page
                navigate(setPasswordUrl);
                return;
            } else if (data.success) {
                localStorage.setItem('isAuthenticated', 'true');
                localStorage.setItem('userRole', data.role);
                if (rememberMe) {
                    localStorage.setItem('rememberedUsername', username);
                } else {
                    localStorage.removeItem('rememberedUsername');
                }
        
                // Check the user's role and navigate accordingly
                if (data.role === 'admin') {
                    navigate('/adminDashboard');
                } else if (data.role === 'regular') {
                    navigate('/home');
                } else {
                    // Handle unexpected role
                    console.error('Unexpected user role', data.role);
                    setErrorMessage('Unexpected user role');
                }
                // navigate('/home');
            } else {
                // Handle login failure
                console.error('Login failed', data);
                setErrorMessage('Login failed');
            }
        })
        .catch(error => {
            console.error('Fetch error:', error);
        });
    };

    useEffect(() => {
        if (rememberedUsername) {
            setRememberMe(true);
            navigate('/home');
        }
    }, [rememberedUsername, navigate]);

    const ForgotPassword = () => {
        const [email, setEmail] = useState('');

        return (
            <input
                type="email"
                id="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                required
            />
        );
    };

    const handleForgotPassword = async () => {
        const emailElement = document.getElementById('email');
        if (!emailElement) {
            console.error('Email input not found');
            return;
        }

        const username = emailElement.value;

        // Make a POST request to the forgotPassword endpoint
        try {
            const response = await fetch('/api/users/forgotPassword', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            if (data.success) {
                alert('Password reset email sent');
            } else {
                alert('Passwpord reset email failed');
            }
        } catch {
            console.error('Error sending password reset email');
        }
    }

    return (
        <div style={{ display: 'flex' }}>
            <img src={MasterAssociationImage} alt="Master Association" style={{width: '62.5%', height: '100vh', objectFit: 'cover' }} />
            <div className="card-block" style={{ maxWidth: '486px', margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                {/* <Helmet>
                    <html lang="en" class="dxChrome dxWindowsPlatform dxWebKitFamily dxBrowserVersion-124 js flexbox flexboxlegacy canvas canvastext webgl no-touch geolocation postmessage no-websqldatabase indexeddb hashchange history draganddrop websockets rgba hsla multiplebgs backgroundsize borderimage borderradius boxshadow textshadow opacity cssanimations csscolumns cssgradients cssreflections csstransforms csstransforms3d csstransitions fontface generatedcontent video audio localstorage sessionstorage webworkers no-applicationcache svg inlinesvg smil svgclippaths cssscrollbar" />
                </Helmet> */}
                <img src={mainlogo} alt="Main Logo" style={{ marginBottom: '150px', width: '45%', height: 'auto' }} />
                
                <form 
                    action="/account/loginmodernthemes" 
                    data-ajax="true" 
                    data-ajax-failure="onModernHomeOwnerAjaxFails" 
                    data-ajax-success="onModernHomeOwnerAjaxSuccess" 
                    data-ajax-url="/Account/DoLoginLMS" 
                    id="form0" 
                    method="post" 
                    noValidate="novalidate"
                    onSubmit={handleSubmit}
                >
                    <div className="form-group form-primary">
                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                    <label className="float-label">Your Email Address</label>
                        <input 
                            type="text" 
                            value={username} 
                            onChange={e => setUsername(e.target.value)} 
                            required 
                            className="form-control"
                            data-val="true"
                            data-val-required="Please enter email address."
                            id="UserName"
                            name="UserName"
                        />
                        <span className="form-bar"></span>
                    </div>
                    <div className="form-group form-primary position-relative"> { /* position-relative */ }
                    <label className="float-label">Password</label>
                        <input 
                            type={passwordVisible ? "text" : "password"} 
                            value={password} 
                            onChange={e => setPassword(e.target.value)} 
                            required 
                            className="form-control"
                            data-val="true"
                            data-val-required="Please enter password."
                            id="Password"
                            name="Password"
                            style={{ paddringRight: '30px'}} 
                        />
                        <FontAwesomeIcon 
                            icon={faEye} 
                            onClick={() => setPasswordVisible(!passwordVisible)} 
                            style={{ cursor: 'pointer', position: 'absolute', zIndex: 999, right: '5px', top: '5px', border: '0px', background: 'none' }}
                        />
                        <span className="form-bar"></span>
                    </div>
                    <div className="row m-t-25 text-left">
                        <div className="col-12 d-flex justify-content-between align-items-center">
                            <div>
                                <label>
                                    <input type="checkbox" checked={rememberMe} onChange={e => setRememberMe(e.target.checked)} />
                                    Remember me
                                </label>
                            </div>
                            <div>
                            <Button 
                                variant="link" 
                                onClick={() => setShowModal(true)}
                                style={{ color: '#505050', textDecoration: 'none' }}
                                onMouseOver={e => e.currentTarget.style.color = 'darkgrey'}
                                onMouseOut={e => e.currentTarget.style.color = '#505050'}
                            >
                                Forgot Password?
                            </Button>
                            </div>
                        </div>
                    </div>
                    <div className="row m-t-30">
                        <div className="col-md-12">
                            <button 
                                id="btnLogin" 
                                type="submit" 
                                className="btn btn-primary" 
                                style={{ 
                                    width: '486px', 
                                    height: '45px', 
                                    borderRadius: '25px', 
                                    backgroundColor: '#505050',
                                    border: 'none'
                                }}
                                onMouseOver={e => e.currentTarget.style.backgroundColor = 'darkgrey'}
                                onMouseOut={e => e.currentTarget.style.backgroundColor = '#505050'}
                            >
                                Sign in
                            </button>
                        </div>
                    </div>
                    <div className="row m-t-30" style={{ marginTop: '21px' }}> {/* Add margin-top here */}
                        <div className="col-md-12">
                            <button 
                                id="btnCreateAccount" 
                                type="button" 
                                className="btn btn-secondary" 
                                style={{ 
                                    width: '486px', 
                                    height: '45px', 
                                    borderRadius: '25px', 
                                    backgroundColor: '#505050',
                                    border: 'none'
                                }}
                                onMouseOver={e => e.currentTarget.style.backgroundColor = 'darkgrey'}
                                onMouseOut={e => e.currentTarget.style.backgroundColor = '#505050'}
                                onClick={() => navigate('/createAccount')}
                            >
                                Create Account
                            </button>
                        </div>
                    </div>
                    <div className="row m-t-30" style={{ marginTop: '21px' }}>
                        <div className="col-md-12">
                            <button
                                id="btnContactUs"
                                type="button"
                                className="btn btn-secondary"
                                style={{
                                    width: '486px',
                                    height: '45px',
                                    borderRadius: '25px',
                                    backgroundColor: '#505050',
                                    border: 'none'
                                }}
                                onMouseOver={e => e.currentTarget.style.backgroundColor = 'darkgrey'}
                                onMouseOut={e => e.currentTarget.style.backgroundColor = '#505050'}
                                onClick={() => navigate('/contact')}
                            >
                                Contact Us
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Forgot Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Please enter your email address and we'll send you instructions on how to reset your password.</p>
                    {showForgotPassword ? <ForgotPassword /> : <input type="email" id="email" className="form-control" placeholder="Email Address" />}
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => handleForgotPassword()}>
                    Request Password Reset
                </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
};

export default LoginPage;