import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './css/navbar.css';

const Navbar = () => {
    const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
    const userRole = localStorage.getItem('userRole');
    const isAdmin = userRole === 'admin';
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('userRole');

        fetch('/api/auth/logout', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => {
                if (data.success) {
                    navigate('/home');
                } else {
                    console.error('Failed to logout:', data.message);
                }
            })
            .catch(err => {
                console.error('Failed to logout:', err);
            });
    };

    return (
        <>
            <nav className="navbar">
                <div className="navbar-content">
                    {/* <img src={mainlogo} alt="Riverwalk Logo" className="navbar-logo" />  */}
                    <h1 className="navbar-title">Riverwalk River District Association</h1>
                    <div className="navbar-buttons"> 
                        {isAuthenticated ? (
                            <>
                                <Link to="/contact" className="navbar-button">Contact Us</Link>
                                <button onClick={handleLogout} className="navbar-button">Logout</button>
                            </>
                        ) : (
                            <>
                                <Link to="/contact" className="navbar-button">Contact Us</Link>
                                {/* <Link to="/login" onClick={() => navigate('/')} className="navbar-button">Sign In</Link> */}
                                <Link to="/" onClick={() => navigate('/')} className="navbar-button">Sign In</Link>
                                <Link to="/createAccount" className="navbar-button">Register</Link>
                            </>
                        )}
                    </div>
                </div>
            </nav>
            <div className="navbar-sub">
                <ul className="navbar-links">
                <li><Link to="/home" className="navbar-sub-link">Home</Link></li>
                {/* <li><Link to="/contact" className="navbar-sub-link">Contact Us</Link></li> */}
                    <li className="navbar-dropdown">
                        <Link to="#" className="navbar-sub-link">Document Library</Link>
                        <div className="navbar-dropdown-content">
                            <Link to="/documents">Documents</Link>
                        </div>
                    </li>
                    {isAdmin && <Link to="/adminDashboard" className="navbar-sub-link">Admin Dashboard</Link>}
                </ul>
            </div>
        </>
    );
};

export default Navbar;