import React, { useState } from 'react';
import Layout from './layout';
import { Form, Button } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';
import './css/contact.css';
const axios = require('axios');

const Contact = () => {
    const navigate = useNavigate();
    const [credentials, setCredentials] = useState({
        name: '',
        association: '',
        propertyAddress: '',
        email: '',
        telephone: '',
        details: ''
    });

    const [isVerified, setIsVerified] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [recaptchaToken, setRecaptchaToken] = useState('');

    const handleRecaptcha = (value) => {
        setIsVerified(!!value);
        setRecaptchaToken(value); // Store the reCAPTCHA token
    };

    const handleChange = (e) => {
        if (e.target.name === 'details' && e.target.value.length > 500) {
            return;
        }
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value
        });

        if (e.target.name === 'email') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            setIsEmailValid(emailRegex.test(e.target.value));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isVerified) {
            alert('Please verify the reCAPTCHA');
            return;
        }

        try {
            const response = await fetch('/api/email/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...credentials,
                    recaptchaToken: recaptchaToken
                })
            });
            const data = await response.json();
            console.log(response);
            console.log(data.success);
            if (response.ok && data.success) {
                alert('Email sent successfully');
                setCredentials({
                    name: '',
                    association: '',
                    propertyAddress: '',
                    email: '',
                    telephone: '',
                    details: ''
                });
                navigate('/');
            } else {
                alert('Error sending email');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error sending email');
        }
    };

    return (
        <Layout>
            <div className="container contact-container">
                <div className="form-container form-container-contact-us">
                    <div className="contact-us-container">
                        <div id="247" className="ccSmallView col-sm-12 new-link" style={{height: 'inherit'}}>
                            <p style={{textAlign: 'left'}}>
                                <span style={{fontSize: '25px'}}>Riverwalk River District Management</span>
                            </p>
                            <p style={{textAlign: 'left'}}>
                                <span style={{fontSize: '20px'}}>Address:</span>
                            </p>
                            <p style={{textAlign: 'left'}}>
                                <span style={{fontSize: '20px'}}>998 Riverwalk Parkway Suite 202 Rock Hill. SC 29730</span>
                            </p>
                            <p style={{textAlign: 'left'}}>
                                <span style={{fontSize: '20px'}}>Phone:</span>
                            </p>
                            <p style={{textAlign: 'left'}}>
                                <span style={{fontSize: '20px'}}>803-574-3322</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="form-container form-container-contact-us">
                    <p style={{textAlign: 'left'}}>
                        <span style={{fontSize: '25px'}}>Send a message using our form:</span>
                    </p>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formAccountNumber">
                            <Form.Label>Name:</Form.Label>
                            <Form.Control type="text" name="name" value={credentials.name} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group controlId="formCompanyName">
                            <Form.Label>Association:</Form.Label>
                            <Form.Control type="text" name="association" value={credentials.association} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group controlId="formPropertyAddress">
                            <Form.Label>Property Address:</Form.Label>
                            <Form.Control type="text" name="propertyAddress" value={credentials.propertyAddress} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group controlId="formEmail">
                            <Form.Label>Email:</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="email" 
                                value={credentials.email} 
                                onChange={handleChange} 
                                style= {{ borderColor: isEmailValid ? '' : 'red' }}
                            />
                            {!isEmailValid && <p style={{ color: 'red' }}>Please enter a valid email address.</p>}
                        </Form.Group>
                        <Form.Group controlId="formTelephone">
                            <Form.Label>Telephone:</Form.Label>
                            <Form.Control type="text" name="telephone" value={credentials.telephone} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group controlId="formDetails">
                            <Form.Label>Details:</Form.Label>
                            <Form.Control as="textarea" rows={3} name="details" value={credentials.details} onChange={handleChange} />
                            <p>{credentials.details.length} / 500</p>
                        </Form.Group>
                        <Form.Group>
                            <ReCAPTCHA sitekey="6LcDYuUpAAAAAK5Dh0NRGgQHpkGhtcngZWdXjQAT" onChange={handleRecaptcha} />
                        </Form.Group>
                        <Form.Group>
                            <Button variant="primary" type="submit">
                                Send
                            </Button>
                        </Form.Group>
                    </Form>
                </div>
            </div>
        </Layout>
    );
};

export default Contact;